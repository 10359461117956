import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ToastProvider, ThemeProvider } from "gatsby-interface"
import GlobalStyles from "../stylesheets/GlobalStyles"
import { SkipNavTrigger, SkipNavTarget } from "@modules/a11y"
import { GenericErrorProvider } from "@modules/modal"

/* Apollo Configuration */
import { ApolloProvider } from "react-apollo"

/* Product Dashboard */
import createApolloClient from "@modules/graphql/client"

/*
 * TODO: Ideally, we should just setup gatsbyjs.com like described here https://github.com/gatsbyjs/gatsby/issues/11225#issuecomment-457211628
 * However, the least potentially impactful solution is to just check if we are not on server side
 **/
const apolloClient = typeof window !== `undefined` ? createApolloClient() : null

export function FrameableLayout({ children }) {
  if (typeof window === `undefined`) {
    return null
  }

  const content = (
    <React.Fragment>
      <SkipNavTarget createAnchor />
      {children}
    </React.Fragment>
  )

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <SkipNavTrigger />
        <GlobalStyles />
        <Helmet
          defaultTitle="Gatsby Cloud"
          titleTemplate="Gatsby Cloud | %s"
          htmlAttributes={{ lang: "en" }}
        />
        <ToastProvider>
          <GenericErrorProvider>{content}</GenericErrorProvider>
        </ToastProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

FrameableLayout.propTypes = {
  pathname: PropTypes.string,
}
