import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { BuildRunnerType, BuildStatus } from "@modules/graphql/types"
import BuildStatusIcon from "@modules/build/shared/components/BuildStatusIcon"
import {
  getBuildStatusVariant,
  getBuildStatusLabels,
  formatRunnerType,
} from "@modules/build/shared/utils"
import { DateTimeDistanceToNow } from "@modules/ui/components/DateTimeDistance"
import BuildCardStatusInfo from "@modules/build/card/components/BuildCardStatusInfo"

const rootCss: ThemeCss = theme => ({ display: `flex`, alignItems: `center` })

const spanCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  marginLeft: theme.space[3],
  "&:first-letter": {
    textTransform: `uppercase`,
  },
})

export type SiteBuildStatusProps = {
  buildStatus?: BuildStatus | null
  buildStartedAt?: Date
  buildCreatedAt?: Date
  buildEndedAt?: Date
  buildDuration?: number | null
  runnerType: BuildRunnerType
  "data-testid": string
  a11yId: string
}

export function SiteBuildStatus({
  buildStatus,
  buildStartedAt,
  buildCreatedAt,
  buildEndedAt,
  buildDuration,
  runnerType = BuildRunnerType.Preview,
  "data-testid": dataTestId,
  a11yId,
}: SiteBuildStatusProps) {
  const siteBuildStatusLabel =
    BuildRunnerType.Preview === runnerType
      ? getBuildStatusVariant(buildStatus, runnerType).label
      : getBuildStatusLabels(buildStatus).label

  return (
    <div css={rootCss} data-cy={dataTestId} data-testid={dataTestId}>
      <BuildStatusIcon
        a11yId={a11yId}
        buildStatus={buildStatus}
        runnerType={runnerType}
      />
      {runnerType !== BuildRunnerType.Preview ? (
        <span css={spanCss}>
          <BuildCardStatusInfo
            buildStatus={buildStatus}
            createdAt={buildCreatedAt}
            startedAt={buildStartedAt}
            endedAt={buildEndedAt}
            duration={buildDuration}
          />
        </span>
      ) : (
        <span css={spanCss}>
          {formatRunnerType(runnerType, true)}{" "}
          {siteBuildStatusLabel.toLowerCase()}{" "}
          {buildStartedAt && buildStatus && (
            <DateTimeDistanceToNow fromDate={new Date(buildStartedAt)} />
          )}
        </span>
      )}
    </div>
  )
}
