import React, { useEffect, useState } from "react"
import { Router, useLocation } from "@gatsbyjs/reach-router"
import * as qs from "query-string"
import { pinCss, SiteCard } from "@modules/site/card/components/SiteCard"
import Loading from "@modules/ui/components/Loading"
import { Redirect } from "@shopify/app-bridge/actions"
import { Provider, useAppBridge } from "@shopify/app-bridge-react"
import { getSessionToken } from "@shopify/app-bridge-utils"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { AuthRedirect } from "./features/Auth"
import {
  StandardPageContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"

import { SiteHostingInfo } from "@modules/cdn/gatsbyCloudCdn/components/SiteHostingInfo"

import { MdClose } from "react-icons/md"
import { MdAdd } from "react-icons/md"
import { useMutation } from "react-apollo"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"

import { Button, EmptyState, EmptyStatePrimaryAction } from "gatsby-interface"

const apiKey = process.env.GATSBY_SHOPIFY_APP_API_KEY
const authServiceUrl = process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL

const shopifyIntegrationQuery = gql`
  query shopifyAppIntegration($shopName: String!, $sessionToken: String!) {
    shopifyAppIntegration(shopName: $shopName, sessionToken: $sessionToken) {
      id
      siteId
      shopName
      shopifyToken
      site {
        id
        publicName
        branch
        stableBuildURL
        previewUrl
        organizationId
        name
        publicName
        buildsEnabled
        previewBuildsEnabled
        organization {
          name
          id
        }
        latestBuild {
          id
          buildStatus
          startedAt
          createdAt
          duration
          endedAt
        }
        latestPreview {
          id
          buildStatus
          createdAt
          startedAt
        }
      }
      hostingDetails {
        id
        domains {
          id
          verified
          verificationMessage
          errorMessage
          dns {
            id
            type
          }
          cloudCdnDefault
          certificateStatus
          primaryDomainId
        }
      }
      gatsbyHostingOn
    }
  }
`

function SiteConnected({ data, refetchShopifyApp }) {
  const {
    id: siteId,
    site: {
      organizationId,
      publicName: siteName,
      organization: { name: organizationName },
    },
    hostingDetails,
    gatsbyHostingOn,
    shopName,
  } = data?.shopifyAppIntegration

  const [
    deleteCmsIntegration,
    {
      called: calledRemoveCMSIntegration,
      loading: loadingRemoveCMSIntegration,
    },
  ] = useMutation(
    gql`
      mutation Disconnect($shopName: String!, $sessionToken: String!) {
        disconnectShopifyAppIntegration(
          shopName: $shopName
          sessionToken: $sessionToken
        ) {
          success
          message
        }
      }
    `
  )

  const app = useAppBridge()

  return (
    <StandardPageContentSection>
      <StandardSingleColumn>
        <h1>{organizationName}</h1>

        <SiteHostingInfo
          siteId={siteId}
          organizationId={organizationId}
          hostingDetails={hostingDetails && hostingDetails[0]}
          disableTooltip
          displayPlatformSummaries={false}
          gatsbyHostingOn={gatsbyHostingOn}
          // css={hostingInfoCss}
        />

        {/* TODO recompose siteCard instead of re-use */}
        <SiteCard
          {...data?.shopifyAppIntegration?.site}
          name={siteName}
          internalLinksEnabled={false}
          goDirectlyToBuildDetails={true}
          displayLighthouse={false}
          organizationId={organizationId}
          Slot={() =>
            !calledRemoveCMSIntegration &&
            !loadingRemoveCMSIntegration && (
              <div css={pinCss}>
                <div>
                  <Button
                    size="S"
                    tone="DANGER"
                    leftIcon={<MdClose />}
                    onClick={async () => {
                      const token = await getSessionToken(app)
                      deleteCmsIntegration({
                        variables: {
                          shopName,
                          vendor: "SHOPIFY",
                          sessionToken: token,
                        },
                      }).then(() => {
                        refetchShopifyApp()
                      })
                    }}
                  >
                    Disconnect site from Shopify
                  </Button>
                </div>
              </div>
            )
          }
        />
      </StandardSingleColumn>
    </StandardPageContentSection>
  )
}

function InstallApp({ app }) {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  useEffect(() => {
    if (query.has("hmac") && !query.has("shopifyToken")) {
      // use app brdige to redirect outside of the iframe
      const redirect = Redirect.create(app)
      redirect.dispatch(
        Redirect.Action.REMOTE,
        `${authServiceUrl}/oauth/shopify-app?shop=${query.get("shop")}`
      )
    }
  }, [])

  return <Loading message={`Loading...`} />
}

function ShopifyApp({ location }) {
  const query = new URLSearchParams(location.search)
  const app = useAppBridge()
  const [sessionToken, setSessionToken] = useState()

  const { data, loading, refetch } = useQuery(shopifyIntegrationQuery, {
    variables: {
      shopName: query.get("shop"),
      sessionToken,
    },
    fetchPolicy: "network-only",
    skip: !sessionToken,
  })

  useEffect(() => {
    let pollInterval
    let mounted = true
    function fetchShopifyIntegration() {
      if (query.has("shopifyToken") && mounted) {
        getSessionToken(app)
          .then(sessionToken => {
            setSessionToken(sessionToken)

            // this can become undefined on unmount because of our async actions
            return refetch?.({
              shopName: query.get("shop"),
              sessionToken,
            })
          })
          .then(() => {
            if (mounted) {
              pollInterval = setTimeout(fetchShopifyIntegration, 5000)
            }
          })
      }
    }

    fetchShopifyIntegration()

    return () => {
      mounted = false

      if (pollInterval) {
        clearTimeout(pollInterval)
        pollInterval = null
      }
    }
  }, [])

  if (loading && !data?.shopifyAppIntegration) {
    return <p>Loading</p>
  }

  /**
   * 1. start polling gatsby cloud for a site that is tied to this store name
   * 2. if no site is found, then show "connect site button"
   *    - open new tab
   *    - show loading state in iframe
   *    - go through create flow in new tab
   * 3. if site is found, then show site data
   */

  if (
    query.has("hmac") &&
    query.has("shop") &&
    !data?.shopifyAppIntegration &&
    !query.has("shopifyToken")
  ) {
    return <InstallApp app={app} />
  }

  if (data?.shopifyAppIntegration) {
    return (
      <SiteConnected
        data={data}
        refetchShopifyApp={refetch}
        sessionToken={sessionToken}
      />
    )
  }

  return (
    <LoginButton
      shopifyToken={query.get("shopifyToken")}
      shopifyStoreFrontToken={query.get("shopifyStoreFrontToken")}
      shop={query.get("shop")}
    />
  )
}

function AppBridgeProvider({ children }) {
  const query = new URLSearchParams(window.location.search)

  if (!query.has("shop") || !query.has("host")) {
    return children
  }

  const config = {
    apiKey,
    shop: query.get("shop"),
    host: query.get("host"),
  }

  return (
    <Provider config={config} query={query}>
      {children}
    </Provider>
  )
}

function LoginButton({ shopifyToken, shopifyStoreFrontToken, shop }) {
  return (
    <StandardPageContentSection>
      <StandardSingleColumn>
        <EmptyState
          heading="You don’t seem to have any sites"
          headingAs="h2"
          text="Connect your Shopify store and have your Gatsby site running in minutes."
          graphic={<EmptyStateGraphic />}
          primaryAction={
            <EmptyStatePrimaryAction
              onClick={() =>
                window.open(
                  `/site-create/login?token=${shopifyToken}&shop=${shop}&storeFrontToken=${shopifyStoreFrontToken}`
                )
              }
              rightIcon={<MdAdd />}
              data-cy="create-site-button"
            >
              Add a Site
            </EmptyStatePrimaryAction>
          }
          variant="BORDERED"
        />
      </StandardSingleColumn>
    </StandardPageContentSection>
  )
}

function AuthLoggedIn({ location }) {
  const query = qs.parse(location.search)

  window.opener.postMessage(query, "*")
  window.close()

  return <p>Logged In</p>
}

function Frameable() {
  const location = useLocation()
  try {
    if (window.self === window.top) {
      const query = new URLSearchParams(location.search)
      window.location = `${authServiceUrl}/oauth/shopify-app?shop=${query.get(
        "shop"
      )}`
      return null
    }
  } catch (e) {
    // no catch
  }

  return (
    <AppBridgeProvider>
      <Router>
        <AuthRedirect path="/frameable/redirect" />

        <ShopifyApp path="/frameable/shopify-app" />

        <AuthLoggedIn path="/frameable/logged-in" component={AuthLoggedIn} />
      </Router>
    </AppBridgeProvider>
  )
}
export default Frameable
