import * as React from "react"
import {
  Heading,
  ThemeCss,
  visuallyHiddenCss,
  Tooltip,
  Link as GatsbyLink,
  Spacer,
} from "gatsby-interface"
import { HostingDetails, PlatformLimitSummary } from "@modules/graphql/types"
import { Link } from "gatsby"
import { sites as text } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { Bull } from "@modules/ui/components/Bull"
import { SiteHostingLimitsInfo } from "@modules/site/informationView/components/SiteHostingLimitsInfo"
import { MdWarning } from "react-icons/md"
import BuildQueueButton from "@modules/site/details/components/BuildQueueButton"

const rootCss: ThemeCss = theme => ({
  marginTop: theme.space[7],
  marginBottom: theme.space[5],
  display: `flex`,
  justifyContent: `space-between`,
  flexWrap: `wrap`,
})

const wrapperCss: ThemeCss = theme => ({
  display: `grid`,
  columnGap: theme.space[8],
  marginTop: theme.space[1],
  width: `100%`,
  alignItems: `start`,

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr max-content`,
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  fontFamily: theme.fonts.body,
  fontWeight: theme.fontWeights.body,
  textTransform: `uppercase`,
  width: `100%`,
  marginBottom: theme.space[2],
})

const domainsCss: ThemeCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  flexWrap: `wrap`,
  color: theme.colors.grey[50],
})

const logoCss: ThemeCss = theme => ({
  color: theme.colors.black,
  marginRight: theme.space[4],
  flexShrink: 0,
  height: "auto",
  width: "16px",
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  textDecoration: `none`,
  color: theme.colors.grey[50],
  display: `flex`,
  alignItems: `center`,

  svg: {
    fontSize: theme.fontSizes[1],
    color: theme.colors.orange[60],
    marginRight: theme.space[1],
  },
})

const validLinkCss: ThemeCss = theme => [
  linkCss(theme),
  {
    fontWeight: theme.fontWeights.bold,
    color: theme.colors.purple[60],
  },
]

const domainWrapperCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.space[2],
  marginBottom: theme.space[4],
  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
})

const domainWrapperBuildQueueCss: ThemeCss = theme => ({
  display: "flex",
  marginLeft: `-${theme.space[4]}`,
  [theme.mediaQueries.tablet]: {
    marginLeft: 0,
    marginRight: `-${theme.space[4]}`,
  },
  [theme.mediaQueries.desktop]: {
    display: "none",
  },
})

const limitsWrapperCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  [theme.mediaQueries.desktop]: {
    alignItems: "flex-end",
    gap: theme.space[4],
  },
})

const limitWrapperBuildQueueCss: ThemeCss = theme => ({
  display: "none",
  [theme.mediaQueries.desktop]: {
    display: "flex",
    marginRight: `-${theme.space[4]}`,
  },
})

export type SiteHostingInfoProps = {
  siteId: string
  organizationId: string
  hostingDetails: HostingDetails
  gatsbyHostingOn: boolean
  platformLimitSummaries?: PlatformLimitSummary[]
  disableTooltip?: boolean
  displayPlatformSummaries?: boolean
  onViewBuildQueueClick?: () => void
}

export function SiteHostingInfo({
  siteId,
  organizationId,
  hostingDetails,
  gatsbyHostingOn,
  platformLimitSummaries,
  disableTooltip = false,
  displayPlatformSummaries = true,
  onViewBuildQueueClick,
}: SiteHostingInfoProps) {
  const linkToSiteSettings = getSiteDetailsTabBasePath(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )

  const viewBuildQueueSource = `Site nav (tabs)`

  if (!hostingDetails || !gatsbyHostingOn) {
    return <Spacer size={7} />
  }

  const domains = hostingDetails?.domains
    ? [...hostingDetails.domains]
        // make the last added domains first
        .reverse()
        // make unverified domains last
        .sort((a, b) => {
          if (a.verified && !b.verified) {
            return -1
          }
          if (!a.verified && b.verified) {
            return 1
          }
          return 0
        })
    : []

  return (
    <div css={rootCss}>
      <div css={wrapperCss}>
        <div css={domainWrapperCss}>
          <div css={domainsCss}>
            <Heading css={headingCss}>
              {text.headers.hostedOnGatsbyCloud}
            </Heading>
            <GatsbyCloudLogo aria-hidden="true" css={logoCss} />
            <span css={visuallyHiddenCss}>
              {text.messages.withDomainsNames}:{" "}
            </span>
            {domains.map((domain, idx) => {
              const { id, verified } = domain

              let renderedLink

              if (verified) {
                renderedLink = (
                  <GatsbyLink
                    href={`https://${id}`}
                    css={validLinkCss}
                    target="_blank"
                  >
                    {id}
                  </GatsbyLink>
                )
              } else if (disableTooltip) {
                renderedLink = null
              } else {
                renderedLink = (
                  <Tooltip
                    label={interpolateMessage<"domain">(
                      text.messages.domainIsNotValidated,
                      { domain: id }
                    )}
                  >
                    <Link to={`${linkToSiteSettings}/hosting`} css={linkCss}>
                      <MdWarning />
                      {id}
                    </Link>
                  </Tooltip>
                )
              }

              return (
                <React.Fragment key={id}>
                  {renderedLink}
                  {idx < domains.length - 1 && <Bull />}
                </React.Fragment>
              )
            })}
          </div>
          <div css={domainWrapperBuildQueueCss}>
            <BuildQueueButton
              onClick={onViewBuildQueueClick}
              source={viewBuildQueueSource}
            />
          </div>
        </div>
        <div css={limitsWrapperCss}>
          <div css={limitWrapperBuildQueueCss}>
            <BuildQueueButton
              onClick={onViewBuildQueueClick}
              source={viewBuildQueueSource}
            />
          </div>
          {displayPlatformSummaries && platformLimitSummaries && (
            <SiteHostingLimitsInfo
              platformLimitSummaries={platformLimitSummaries}
            />
          )}
        </div>
      </div>
    </div>
  )
}
