import React from "react"
import PropTypes from "prop-types"
import { FrameableLayout } from "@modules/ui/layouts/FrameableLayout"
import FrameableRoot from "../frameable-root"

const isBrowser = global.window

function Frameable({ location }) {
  return isBrowser ? (
    <FrameableLayout pathname={location.pathname}>
      <FrameableRoot />
    </FrameableLayout>
  ) : null
}

Frameable.propTypes = {
  location: PropTypes.object,
}

export default Frameable
