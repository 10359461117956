import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { useTracker, GAEventType } from "@modules/analytics"
import { Link } from "gatsby"
import { sites as text } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"

const nameCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  color: theme.colors.grey[90],
  margin: 0,
  marginRight: theme.space[5],
  fontFamily: theme.fonts.body,
})

const linkCss: ThemeCss = theme => ({
  textDecoration: `none`,

  "&:hover": {
    textDecoration: `underline`,
  },
})

export type SiteNameLinkProps = {
  siteName: string
  pathToDetails: string
  internalLinksEnabled?: boolean
}

export function SiteNameLink({
  siteName,
  pathToDetails,
  internalLinksEnabled = true,
  ...rest
}: SiteNameLinkProps) {
  const { trackGoogleAnalytics } = useTracker()

  return (
    <h3 css={nameCss} {...rest}>
      <Link
        aria-label={interpolateMessage<"siteName">(
          text.messages.goToSiteDetails,
          { siteName }
        )}
        to={
          (internalLinksEnabled ? "" : process.env.GATSBY_DASHBOARD_URL) +
          pathToDetails
        }
        css={linkCss}
        onClick={() => {
          trackGoogleAnalytics({
            eventType: GAEventType.Click,
            category: `Product Engagement - Dashboard`,
            label: {
              loc: `in-page`,
              text: `view details`,
            },
          })
        }}
        target={internalLinksEnabled ? undefined : "_blank"}
      >
        {siteName}
      </Link>
    </h3>
  )
}
