import * as React from "react"
import {
  BuildRunnerType,
  PreviewStatus,
  BuildStatus,
  Build,
} from "@modules/graphql/types"
import { useSiteChangedSubscription } from "../../shared/queries.generated"
import { MdArrowForward } from "react-icons/md"
import { FaThumbtack } from "react-icons/fa"
import { Button, Spacer, ThemeCss, LinkButton } from "gatsby-interface"
import { SiteNameLink } from "./SiteNameLink"
import { SiteBuildStatus } from "./SiteBuildStatus"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import { getPreviewStatus } from "@modules/build/shared/utils"
import { getCardBaseStyles } from "@modules/ui/stylesheets/card"
import {
  getPathToSiteDetails,
  getPathToBuildDetails,
} from "@modules/site/details/utils"
import { LighthouseScores } from "@modules/metrics/components/LighthouseScores"

import { sites as text } from "@modules/locales/default.js"

const rootCss: ThemeCss = theme => [
  getCardBaseStyles(theme),
  {
    padding: 0,
    paddingTop: theme.space[6],
    display: `grid`,
    gap: theme.space[5],
  },
]

const rowCss: ThemeCss = theme => ({
  display: `grid`,
  paddingRight: theme.space[7],
  paddingLeft: theme.space[7],

  [theme.mediaQueries.tablet]: {
    alignItems: `center`,
    display: `flex`,
  },
})

const headerCss: ThemeCss = theme => [
  rowCss(theme),
  {
    columnGap: theme.space[4],
    gridTemplateColumns: `1fr auto`,
    rowGap: theme.space[3],
  },
]

const nameCss: ThemeCss = theme => ({
  gridColumn: `1 / 3`,
})

const detailsCss: ThemeCss = theme => [
  rowCss(theme),
  {
    rowGap: theme.space[2],
  },
]

const errorsCss: ThemeCss = theme => ({})

export const pinCss: ThemeCss = theme => ({
  justifySelf: `end`,
  alignSelf: `start`,

  [theme.mediaQueries.tablet]: {
    marginLeft: `auto`,
    alignSelf: `none`,
  },
})

const branchCss: ThemeCss = _theme => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
})

const lighthouseCss: ThemeCss = theme => [
  rowCss(theme),
  {
    marginTop: `-${theme.space[2]}`,
    paddingBottom: theme.space[6],
  },
]

export type SiteCardProps = {
  id: string
  organizationId: string
  latestBuild?: Build | null
  latestPreview?: Build | null
  name: string
  repositoryId: string
  previewStatus?: PreviewStatus | null
  buildsEnabled?: boolean | null
  previewBuildsEnabled?: boolean | null
  incrementalPreviewsEnabled?: boolean | null
  branch: string
  onPinSite: () => void
  isPinned: boolean
  enablePinning: boolean
  goDirectlyToBuildDetails?: boolean
  internalLinksEnabled?: boolean
  displayLighthouse?: boolean
  Slot?: React.ComponentType<{ siteId: string }>
}

export function SiteCard({
  id: siteId,
  organizationId,
  latestBuild,
  latestPreview,
  name: siteName,
  previewStatus,
  buildsEnabled,
  previewBuildsEnabled,
  incrementalPreviewsEnabled,
  branch,
  onPinSite,
  isPinned,
  enablePinning,
  internalLinksEnabled = true,
  goDirectlyToBuildDetails = false,
  displayLighthouse = true,
  Slot,
}: SiteCardProps) {
  useSiteChangedSubscription({ variables: { id: siteId } })
  const pathToDetails = getPathToSiteDetails(siteId, organizationId)
  const pathToLatestBuildDetails =
    latestBuild && getPathToBuildDetails(latestBuild.id, siteId, organizationId)
  const buildStatus = latestBuild && latestBuild.buildStatus
  const buildStartedAt = latestBuild && latestBuild.startedAt
  const buildCreatedAt = latestBuild && latestBuild.createdAt
  const buildDuration = latestBuild && latestBuild.duration
  const buildEndedAt = latestBuild && latestBuild.endedAt
  const latestBuildId = latestBuild?.id
  const isSuccessBuild = buildStatus === BuildStatus.Success

  const dashboardUrl = process.env.GATSBY_DASHBOARD_URL as string

  const viewErrorLink = `${(internalLinksEnabled ? "" : dashboardUrl) +
    pathToLatestBuildDetails}#errors`

  return (
    <article
      css={theme => [
        rootCss(theme),
        (!isSuccessBuild || !displayLighthouse) && {
          paddingBottom: theme.space[6],
        },
      ]}
      data-cy={`${siteName}-card`}
    >
      <div css={headerCss}>
        <SiteNameLink
          css={nameCss}
          siteName={siteName}
          internalLinksEnabled={internalLinksEnabled}
          pathToDetails={
            (goDirectlyToBuildDetails
              ? pathToLatestBuildDetails
              : pathToDetails) as string
          }
        />

        {branch && <SiteBranch css={branchCss}>{branch}</SiteBranch>}

        {enablePinning && (
          <Button
            size="S"
            rightIcon={<FaThumbtack />}
            variant={isPinned ? "PRIMARY" : "SECONDARY"}
            onClick={onPinSite}
            css={pinCss}
          >
            {isPinned ? "Unpin" : "Pin"}
          </Button>
        )}
      </div>
      <div css={detailsCss}>
        {buildsEnabled && (
          <React.Fragment>
            <SiteBuildStatus
              buildStatus={buildStatus}
              runnerType={BuildRunnerType.Builds}
              buildStartedAt={buildStartedAt}
              buildCreatedAt={buildCreatedAt}
              buildEndedAt={buildEndedAt}
              buildDuration={buildDuration}
              data-testid="build-status"
              a11yId={`SiteCard--${siteId}--BuildStatus`}
            />
            <Spacer size={4} direction="horizontal" />
          </React.Fragment>
        )}

        {buildStatus === BuildStatus.Error && (
          <React.Fragment>
            <LinkButton
              to={viewErrorLink}
              variant="SECONDARY"
              size="S"
              tone="DANGER"
              rightIcon={<MdArrowForward />}
              css={errorsCss}
              target="__blank"
            >
              {text.actions.viewErrors}
            </LinkButton>
            <Spacer size={6} direction="horizontal" />
          </React.Fragment>
        )}

        {previewBuildsEnabled && (
          <SiteBuildStatus
            buildStatus={
              (incrementalPreviewsEnabled && latestPreview?.buildStatus) ||
              getPreviewStatus(latestPreview?.buildStatus || ``, previewStatus)
            }
            runnerType={BuildRunnerType.Preview}
            data-testid="preview-status"
            a11yId={`SiteCard--${siteId}--PreviewStatus`}
          />
        )}

        {!!Slot && <Slot siteId={siteId} />}
      </div>

      {displayLighthouse && isSuccessBuild && (
        <div css={lighthouseCss}>
          <LighthouseScores
            siteId={siteId}
            organizationId={organizationId}
            buildId={latestBuildId}
            branch={branch}
          />
        </div>
      )}
    </article>
  )
}
